<template>
  <div class="setindex" v-loading="loading">
    <div class="buttonBox">
      <el-button type="text" icon="el-icon-back" @click="cancel"></el-button>
      <el-button @click="addClick">保存 </el-button>
      <el-button v-if="$route.params.id && !(ruleForm.menuType == 0)" @click="loadSql"
        >加载SQL</el-button
      >
    </div>
    <el-tabs v-model="activeName" style="width: 98%; margin: 0 auto">
      <el-tab-pane label="基本配置" name="first">
        <div class="formBox">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="120px"
            :rules="ruleInfo"
            inline
            class="demo-ruleForm"
          >
            <el-form-item label="数据集名称" prop="dsName">
              <el-input
                v-model="ruleForm.dsName"
                placeholder="数据集名称"
                size="small"
                style="width: 180px"
              ></el-input>
            </el-form-item>
            <el-form-item label="菜单类型">
              <el-select
                clearable
                v-model="ruleForm.menuType"
                placeholder="菜单类型"
                :disabled="prohibit"
                size="small"
                style="width: 180px"
              >
                <el-option label="目录" value="0"></el-option>
                <el-option label="菜单" value="1"></el-option>
                <el-option label="明细表" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜单图标">
              <IconSelect v-model="ruleForm.icon" clearable placeholder="菜单图标" />
            </el-form-item>
            <template v-if="ruleForm.menuType == 1 || ruleForm.menuType == 2">
              <el-form-item label="外键">
                <el-input
                  v-model="ruleForm.referCondition"
                  placeholder="外键"
                  size="small"
                  style="width: 180px"
                ></el-input>
              </el-form-item>
              <!-- 如果当前编辑的是菜单，上级模块为目录 -->
              <el-form-item label="上级目录" v-if="ruleForm.menuType == 1" prop="pid">
                <el-select
                  clearable
                  v-model="ruleForm.pid"
                  placeholder="上级模块"
                  size="small"
                  style="width: 180px"
                >
                  <el-option label="顶级" value="0"></el-option>
                  <el-option
                    v-for="(item, index) in superiorModule"
                    :key="index"
                    :label="item.dsName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <!-- 如果当前编辑的事从表 上级模块为菜单 -->
              <el-form-item label="上级菜单" v-if="ruleForm.menuType == 2">
                <el-select
                  clearable
                  v-model="ruleForm.pid"
                  placeholder="上级菜单"
                  size="small"
                  style="width: 180px"
                >
                  <el-option
                    v-for="(item, index) in menuOptions"
                    :key="index"
                    :label="item.dsName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否穿透">
                <el-radio-group v-model="isDrill" size="small" style="width: 180px">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="穿透条件" v-if="isDrill">
                <el-input
                  v-model="ruleForm.drillContent"
                  placeholder="穿透条件"
                  size="small"
                  style="width: 180px"
                ></el-input>
              </el-form-item>
              <el-form-item label="数据库链接" prop="connId">
                <el-select
                  clearable
                  v-model="ruleForm.connId"
                  placeholder="数据库链接"
                  @change="connNameChange"
                  size="small"
                  style="width: 180px"
                >
                  <el-option
                    v-for="(item, index) in getdataBasedata"
                    :key="index"
                    :label="item.connName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数据集类型" prop="dsType">
                <el-select
                  clearable
                  v-model="ruleForm.dsType"
                  placeholder="数据库链接"
                  size="small"
                  style="width: 180px"
                >
                  <el-option label="基础数据集" value="基础数据集"></el-option>
                  <el-option label="聚合数据集" value="聚合数据集"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否App查询" prop="isApp">
                <el-select
                  v-model="ruleForm.isApp"
                  placeholder="查询符"
                  size="small"
                  style="width: 180px"
                >
                  <el-option label="否" :value="0"></el-option>
                  <el-option label="是" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择数据集">
                <el-select
                  clearable
                  multiple
                  v-model="aggrDsIds"
                  placeholder="选择数据集"
                  @change="handleChange"
                  size="small"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in databaseMap"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-checkbox
                  v-model="hasRemark"
                  label="是否有备注列？"
                  size="small"
                  style="width: 180px"
                />
              </el-form-item>
            </template>
            <el-form-item label="顺序">
              <el-input
                v-model="ruleForm.showOrder"
                placeholder="顺序"
                size="small"
                style="width: 180px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="SQL"
              required
              v-if="!ruleForm.menuType || ruleForm.menuType !== '0'"
            >
              <el-button type="primary" @click="handleEditSQL">填写SQL语句</el-button>
            </el-form-item>
          </el-form>
        </div></el-tab-pane
      >
      <el-tab-pane
        label="字段配置"
        name="second"
        v-if="!ruleForm.menuType || ruleForm.menuType !== '0'"
      >
        <el-tabs
          v-if="ruleForm.menuType == 1 || ruleForm.menuType == 2"
          @tab-click="tabClick"
          v-model="tabsValue"
          type="border-card"
          style="width: 100%; height: calc(50% - 20px)"
        >
          <el-tab-pane label="字段属性" style="height: 100%">
            <el-button
              v-if="ruleForm.menuType == 1 && $route.params.id"
              type="primary"
              size="small"
              @click="addFied"
              >新增字段</el-button
            >

            <el-table
              border
              :data="shxiintableData"
              height="600px"
              style="width: 100%; margin: 5px 0"
              :row-class-name="tableRowClassName"
              @cell-click="cellClick"
            >
              <el-table-column prop="showOrder" label="显示顺序" width="100px">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.showOrder"
                    size="mini"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'showOrder'"
                    :ref="scope.row.id + 'showOrder'"
                    placeholder="显示顺序"
                    @blur="inputblur(scope.row)"
                  ></el-input>
                  <span v-else style="margin-left: 10px">{{ scope.row.showOrder }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="fname" label="字段名称"  width="100px">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.fname"
                    size="mini"
                    disabled
                    v-if="scope.row.index == rowIndex && cloumIndex == 'fname'"
                    :ref="scope.row.id + 'fname'"
                    placeholder="字段名称"
                    @blur="inputblur(scope.row)"
                  ></el-input>
                  <span v-else style="margin-left: 10px">{{ scope.row.fname }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="showName" label="显示名称"  width="100px">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.showName"
                    size="mini"
                    disabled
                    v-if="scope.row.index == rowIndex && cloumIndex == 'showName'"
                    :ref="scope.row.id + 'showName'"
                    placeholder="显示名称"
                    @blur="inputblur(scope.row)"
                  ></el-input>
                  <span v-else style="margin-left: 10px">{{ scope.row.showName }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="ftype" label="字段类型"  width="100px">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.ftype"
                    size="mini"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'ftype'"
                    :ref="scope.row.id + 'ftype'"
                    placeholder="字段类型"
                    @change="inputblur(scope.row)"
                  >
                    <el-option label="varchar" :value="0"></el-option>
                    <el-option label="number" :value="1"></el-option>
                    <el-option label="date" :value="2"></el-option>
                    <el-option label="decimal" :value="3"></el-option>
                    <el-option label="text" :value="4"></el-option>
                  </el-select>
                  <span v-else style="margin-left: 10px">{{
                    $map.ftypeMap[scope.row.ftype]
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="addType" label="虚拟字段"  width="100px">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{
                    $map.addType[scope.row.addType]
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="filterType" label="查询符"  width="100px">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.filterType"
                    size="mini"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'filterType'"
                    :ref="scope.row.id + 'filterType'"
                    placeholder="查询符"
                    @change="inputblur(scope.row)"
                  >
                    <el-option label="模糊" :value="0"></el-option>
                    <el-option label="等值" :value="1"></el-option>
                    <el-option label="范围" :value="2"></el-option>
                    <el-option label="比较" :value="3"></el-option>
                    <el-option label="数据字典" :value="4"></el-option>
                  </el-select>
                  <span v-else style="margin-left: 10px">{{
                    $map.filterTypeMap[scope.row.filterType]
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="showForRoles" label="可见角色"  width="100px">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.showForRoles"
                    size="mini"
                    multiple
                    collapse-tags
                    @remove-tag="(removedTag) => handleTagRemove(removedTag, scope.row)"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'showForRoles'"
                    :ref="scope.row.id + 'showForRoles'"
                    placeholder="可见角色"
                    @visible-change="(val) => (val ? '' : inputblur(scope.row))"
                  >
                    <el-button
                      class="allButton"
                      @click="handleGetAllChange(scope.row, 'list')"
                      >全选</el-button
                    >
                    <el-button
                      class="allButton"
                      @click="handleClearAllChange(scope.row, 'list')"
                      >全不选</el-button
                    >
                    <el-option
                      v-for="item in rolesOption"
                      :label="item.roleName"
                      :value="item.id"
                      :key="item.id"
                      >{{ item.roleName }}</el-option
                    >
                  </el-select>
                  <span
                    v-else
                    style="
                      margin-left: 10px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ scope.row.showForRolesStr }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column prop="colWidth" label="列宽"  width="100px">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.colWidth"
                    size="mini"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'colWidth'"
                    v-show="!(scope.row.addType == 1)"
                    :ref="scope.row.id + 'colWidth'"
                    placeholder="列宽"
                    @blur="inputblur(scope.row)"
                  ></el-input>
                  <span v-else style="margin-left: 10px">{{ scope.row.colWidth }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="isFixed" width="100" label="固定表头" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isFixed"
                    :disabled="scope.row.addType == 1"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="decimalDigit" label="保留位数"  width="100px">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.decimalDigit"
                    size="mini"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'decimalDigit'"
                    v-show="!(scope.row.addType == 1)"
                    :ref="scope.row.id + 'decimalDigit'"
                    placeholder="保留位数"
                    @blur="inputblur(scope.row)"
                  ></el-input>
                  <span v-else style="margin-left: 10px">{{
                    scope.row.decimalDigit
                  }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="isWhereField" width="100" label="是否显示" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isWhereField" @change="whetherToDisplay(scope.row)"></el-checkbox>
            </template>
          </el-table-column>  -->
              <!-- <el-table-column prop="isRow2column" width="100" label="是否行转列" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isRow2column" @change="rowTransfer(scope.row)"></el-checkbox>
            </template>
          </el-table-column> -->
              <!-- <el-table-column prop="isRemarkPrimaryKey" width="100" label="是否备注主键" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isRemarkPrimaryKey" @change="commentPK(scope.row)"></el-checkbox>
            </template>
          </el-table-column> -->
              <el-table-column
                prop="isFilter"
                width="100"
                label="是否筛选"
                align="center"
              >
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isFilter"
                    @change="changelistsharow(scope.row)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                prop="moduleType"
                width="120"
                label="筛选框类型"
                align="center"
              >
                <template slot-scope="scope">
                  <el-select
                    :ref="scope.row.id + 'moduleType'"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'moduleType'"
                    v-model="scope.row.moduleType"
                    placeholder="查询框类型"
                    size="mini"
                    @change="inputblur(scope.row)"
                  >
                    <el-option label="输入框" :value="0"></el-option>
                    <el-option label="下拉框" :value="1"></el-option>
                    <el-option label="日期选择框" :value="2"></el-option>
                    <el-option label="日期范围选择框" :value="3"></el-option>
                  </el-select>
                  <span v-else style="margin-left: 10px">{{
                    $map.moduleType[scope.row.moduleType]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="sqlContent" label="筛选sql语句"  width="120px">
                <template slot-scope="scope">
                  <el-input
                    :ref="scope.row.id + 'sqlContent'"
                    type="textarea"
                    v-if="scope.row.index == rowIndex && cloumIndex == 'sqlContent'"
                    v-model="scope.row.sqlContent"
                    placeholder="查询sql语句"
                    size="mini"
                    @blur="inputblur(scope.row)"
                  />
                  <span v-else style="margin-left: 10px">{{ scope.row.sqlContent }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="isWhereMulti"
                width="120"
                label="多条件筛选"
                align="center"
              >
                <template slot-scope="scope">
                  <el-checkbox
                    :disabled="scope.row.addType == 1"
                    v-model="scope.row.isWhereMulti"
                    @change="queryMultipleSelections(scope.row)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="filterValue" label="筛选默认值"  width="120">
                <template slot-scope="scope">
                  <div v-if="scope.row.index == rowIndex && cloumIndex == 'filterValue'">
                    <el-date-picker
                      :ref="scope.row.id + 'filterValue'"
                      v-if="scope.row.moduleType == 3"
                      style="margin: 10px; width: 200px"
                      size="mini"
                      v-model="scope.row.filterValue"
                      type="daterange"
                      align="right"
                      value-format="yyyy-MM-dd"
                      unlink-panels
                      range-separator="至"
                      @change="inputblur(scope.row)"
                    />
                    <el-date-picker
                      :ref="scope.row.id + 'filterValue'"
                      @change="inputblur(scope.row)"
                      v-else-if="scope.row.moduleType == 2"
                      value-format="yyyy-MM-dd"
                      v-model="scope.row.filterValue"
                      style="margin: 10px; width: 140px"
                      size="mini"
                      type="date"
                    />
                    <el-input
                      :ref="scope.row.id + 'filterValue'"
                      type="text"
                      v-else
                      v-model="scope.row.filterValue"
                      placeholder="筛选默认值"
                      size="mini"
                      @blur="inputblur(scope.row)"
                    />
                  </div>
                  <span v-else style="margin-left: 10px">{{
                    scope.row.filterValue
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="isQuery" width="100" label="是否查询" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isQuery"
                    :disabled="scope.row.addType == 1"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="isTotal" width="100" label="是否合计" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isTotal"
                    :disabled="scope.row.addType == 1"
                    @change="changelistisSum(scope.row)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="isSubtotalGroup" width="150" label="是否小计分组" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isSubtotalGroup" @change="changelistisSum(scope.row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="isSubtotal" width="100" label="是否小计" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isSubtotal" @change="changelistisSum(scope.row)"></el-checkbox>
            </template>
          </el-table-column> -->
              <el-table-column width="100" label="操作" fixed="right" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="setClick(scope.row, scope.$index)"
                    icon="el-icon-edit"
                  ></el-button>
                  <el-button
                    type="text"
                    style="color: red"
                    v-if="scope.row.addType == 1"
                    @click="delClick(scope.row, scope.$index)"
                    icon="el-icon-delete"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="数据预览" style="height: 100%">
            <el-table
              border
              :data="yulatableData"
              style="width: 100%"
              height="100%"
              @header-dragend="headerDragend"
            >
              <el-table-column
                v-for="(item, index) in yulatableDatacloume"
                :key="index"
                :prop="item.fname"
                :label="item.showName"
                :width="item.colWidth ? item.colWidth : '150'"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
          </el-tab-pane> </el-tabs
      ></el-tab-pane>
    </el-tabs>

    <!-- 数据集操作编辑内字段属性操作（编辑）弹窗 -->
    <el-dialog
      :title="form.id ? '修改' : '新增'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" style="display: flex; flex-wrap: wrap">
        <el-form-item label="显示顺序" :label-width="formLabelWidth">
          <el-input v-model="form.showOrder" placeholder="显示顺序"></el-input>
        </el-form-item>
        <el-form-item label="字段名称" :label-width="formLabelWidth">
          <el-input v-model="form.fname" placeholder="字段名称"></el-input>
        </el-form-item>
        <el-form-item label="显示名称" :label-width="formLabelWidth">
          <el-input v-model="form.showName" placeholder="显示名称"></el-input>
        </el-form-item>
        <el-form-item label="字段类型" :label-width="formLabelWidth">
          <el-select v-model="form.ftype" placeholder="字段类型">
            <el-option label="varchar" :value="0"></el-option>
            <el-option label="number" :value="1"></el-option>
            <el-option label="date" :value="2"></el-option>
            <el-option label="decimal" :value="3"></el-option>
            <el-option label="text" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="保留位数"
          v-show="!(form.addType == 1)"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.decimalDigit" placeholder="保留位数"></el-input>
        </el-form-item>
        <el-form-item
          label="是否查询"
          v-show="!(form.addType == 1)"
          :label-width="formLabelWidth"
          class="paixu"
        >
          <el-checkbox v-model="form.isQuery"></el-checkbox>
        </el-form-item>
        <el-form-item label="查询符" :label-width="formLabelWidth">
          <el-select v-model="form.filterType" placeholder="查询符">
            <el-option label="模糊" :value="0"></el-option>
            <el-option label="等值" :value="1"></el-option>
            <el-option label="范围" :value="2"></el-option>
            <el-option label="比较" :value="3"></el-option>
            <el-option label="数据字典" :value="4"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="可见角色" :label-width="formLabelWidth">
          <el-select
            v-model="form.showForRoles"
            size="mini"
            multiple
            placeholder="可见角色"
          >
            <el-button class="allButton" @click="handleGetAllChange(form.showForRoles)"
              >全选</el-button
            >
            <el-button class="allButton" @click="handleClearAllChange(form.showForRoles)"
              >全不选</el-button
            >
            <el-option
              v-for="item in rolesOption"
              :label="item.roleName"
              :value="item.id"
              :key="item.id"
              >{{ item.roleName }}</el-option
            >
          </el-select>
        </el-form-item>

        <el-form-item label="是否筛选" :label-width="formLabelWidth" class="paixu">
          <el-checkbox v-model="form.isFilter"></el-checkbox>
        </el-form-item>

        <!-- <el-form-item label="多选查询语句" class="textareainputs">
          <el-input type="textarea" v-model="form.whereMultiSqlContent"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="行转列语句" v-show="!(form.addType==1)" class="textareainputs">
          <el-input type="textarea" v-model="form.r2cSqlContent"></el-input>
        </el-form-item> -->

        <el-form-item label="筛选默认值" :label-width="formLabelWidth" class="paixu">
          <el-date-picker
            v-if="form.moduleType == 3"
            style="margin: 10px; width: 200px"
            size="mini"
            type="daterange"
            v-model="form.filterValue"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
          />
          <el-date-picker
            v-else-if="form.moduleType == 2"
            value-format="yyyy-MM-dd"
            v-model="form.filterValue"
            style="margin: 10px; width: 140px"
            size="mini"
            type="date"
          />
          <el-input
            type="text"
            v-else
            v-model="form.filterValue"
            placeholder="筛选默认值"
            size="mini"
          />
        </el-form-item>

        <!-- <el-form-item label="是否显示" :label-width="formLabelWidth" class="paixu">
          <el-checkbox v-model="form.isWhereField"></el-checkbox>
        </el-form-item> -->
        <el-form-item label="筛选支持多选" :label-width="formLabelWidth" class="paixu">
          <el-checkbox
            v-model="form.isWhereMulti"
            :disabled="form.addType == 1"
          ></el-checkbox>
        </el-form-item>

        <!-- <el-form-item label="是否行转列" :label-width="formLabelWidth" class="paixu">
          <el-checkbox v-model="form.isRow2column"></el-checkbox>
        </el-form-item>
        <el-form-item label="是否备注主键" :label-width="formLabelWidth" class="paixu">
          <el-checkbox v-model="form.isRemarkPrimaryKey"></el-checkbox>
        </el-form-item> -->
        <el-form-item label="筛选框类型" :label-width="formLabelWidth">
          <el-select v-model="form.moduleType" placeholder="查询框类型">
            <el-option label="输入框" :value="0"></el-option>
            <el-option label="下拉框" :value="1"></el-option>
            <el-option label="日期选择框" :value="2"></el-option>
            <el-option label="日期范围选择框" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="筛选sql语句" :label-width="formLabelWidth">
          <el-input
            v-model="form.sqlContent"
            type="textarea"
            placeholder="查询sql语句"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否合计"
          v-show="!(form.addType == 1)"
          :label-width="formLabelWidth"
          class="paixu"
        >
          <el-checkbox v-model="form.isTotal"></el-checkbox>
        </el-form-item>
        <!-- <el-form-item label="是否小计分组" :label-width="formLabelWidth" class="paixu">
          <el-checkbox v-model="form.isSubtotalGroup"></el-checkbox>
        </el-form-item>
        <el-form-item label="是否小计" :label-width="formLabelWidth" class="paixu">
          <el-checkbox v-model="form.isSubtotal"></el-checkbox>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisibleok">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="SQL语句" :modal="false" :visible.sync="dialogV">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        v-model="sqlContent"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmitSQL">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getdataBaseLinklist,
  adddataSetlist,
  getdataBaseLinkDetailt,
  setDeiledataSetlist,
  deleteDataSetList,
  getdataSetlistPage,
  getloadSql,
  findByMenuType,
  findByMenuList,
  getRoleListByUserName,
} from "@/api/dataSet/index.js";
import IconSelect from "@/components/IconSelect";
import clone from "clone";
export default {
  name: "setindex",
  components: { IconSelect },
  data() {
    return {
      ruleInfo: {
        dsName: [{ required: true, message: "请填写数据名称", trigger: "blur" }],
        pid: [{ required: true, message: "请选择上级目录", trigger: "change" }],
        connId: [{ required: true, message: "请选择数据库链接", trigger: "change" }],
        dsType: [{ required: true, message: "请选择数据集类型", trigger: "change" }],
        isApp: [{ required: true, message: "请选择是否APP查询", trigger: "change" }],
      },
      activeName: "first",
      sqlContent: "",
      dialogV: false,
      menuOptions: [], // 上级菜单选项 如果是从表根据这里选择所属菜单
      tabsValue: 0,
      rowIndex: 0,
      loading: false, // loading
      cloumIndex: "",
      rowValue: null,
      ruleForm: {
        dsName: null, //数据集名称
        connName: null,
        connId: null, //数据库链接
        dsType: null, //数据集类型
        sqlContent: null, //SQL
        id: "",
        icon: "",
        aggrDsIds: "", //选择数据集
        menuType: "",
        aggrDsNames: [],
        showOrder: null, //顺序
        referCondition: null, //外键
        pid: "", //上级模块
        drillContent: null, //穿透条件
        isApp: "",
      },
      hasRemark: false, //是否有备注列？
      isDrill: false, //是否穿透
      shxiintableData: [], //字段属性表头
      yulatableData: [], //数据预览表头
      yulatableDatacloume: [], //数据预览渲染
      getdataBasedata: [], //数据库链接数据
      form: {
        fname: null, //字段名称
        showName: null, //显示名称
        decimalDigit: "", //保留位数
        ftype: null, //字段类型
        filterType: "", //查询符
        id: null,
        isQuery: false, //是否查询
        isFilter: false, //是否筛选
        isTotal: false, //是否合计
        isSubtotalGroup: null, //是否小计分组
        isSubtotal: null, //是否小计
        isWhereField: true, //是否显示
        isWhereMulti: false, //查询支持多选
        isFixed: false, // 固定表头
        filterValue: "", // 默认值
        // isRow2column: false, //是否行转列
        // isRemarkPrimaryKey: false, //是否备注主键
        sqlContent: "", //多选查询语句
        r2cSqlContent: null, //行转列语句
        sqlContent: "",
        showForRoles: [],
        moduleType: "",
      },
      dialogFormVisible: false, //字段属性操作（编辑）弹框确定/取消
      formLabelWidth: "110px",
      databaseMap: [], //选择数据集下拉框数据
      superiorModule: [], // 选择目录下拉数据
      aggrDsIds: [],
      prohibit: true, // 是否可以修改菜单类型
      editRowIndex: null, // 编辑字段属性的数据下标
      rolesOption: [], // 可见角色下拉数据
    };
  },

  mounted() {
    // 数据库链接下拉框选项渲染
    this.getdataBase();
    if (this.$route.params.id) this.dateillist();
    else this.prohibit = false;
  },
  watch: {
    dialogFormVisible(val) {
      if (!val) this.form = {};
    },
  },
  methods: {
    handleEditSQL() {
      this.dialogV = true;
      this.sqlContent = this.ruleForm.sqlContent;
    },
    handleSubmitSQL() {
      this.dialogV = false;
      this.ruleForm.sqlContent = this.sqlContent;
    },
    // 可见角色全选
    handleGetAllChange(row, list) {
      if (list == "list") {
        row.showForRoles = this.rolesOption.map((item) => item.id);
      } else {
        this.form.showForRoles = this.rolesOption.map((item) => item.id);
      }
    },
    // 可见角色全不选
    handleClearAllChange(row, list) {
      if (list == "list") row.showForRoles = [];
      else this.form.showForRoles = [];
    },
    handleTagRemove(removedTag, row) {
      setDeiledataSetlist(row).then((res) => {
        this.loading = true;
        this.$message.success(res.msg || "操作成功");
        this.rowValue = {};
        this.dateillist(); // 数据预览界面
      });
    },

    addFied() {
      this.form.dsId = this.$route.params.id;
      (this.form.addType = 1), (this.form.isQuery = 0), (this.dialogFormVisible = true);
    },

    // tab 被选中时触发
    tabClick(val) {
      if (this.tabsValue == 0) {
        this.dateillist();
      }
    },
    // 当拖动表头改变了列的宽度的时候会触发该事件
    headerDragend(newWidth, odlwidth, column) {
      let cloumvalue = {};
      for (let i = 0; i < this.yulatableDatacloume.length; i++) {
        if (this.yulatableDatacloume[i].fname === column.property) {
          cloumvalue = this.yulatableDatacloume[i];
        }
      }
      cloumvalue.colWidth = Math.round(newWidth) + "";
      // this.$nextTick(() => {
      //   this.changelistrow(cloumvalue);
      // });
    },
    // 判断菜单类型
    changelistrow() {
      const list = this.shxiintableData.map((row) => {
        row.filterValue = row.filterValue || "";
        if (row.filterValue instanceof Array) {
          row.filterValue = row.filterValue.length ? row.filterValue?.join("至") : "";
        }
        return row;
      });

      // 判断字段空值 null 为空
      setDeiledataSetlist(list).then((res) => {
        // this.loading = true;
        this.rowValue = {};
        // this.dateillist(); // 数据预览界面
      });
    },
    // 数据预览界面
    dateillist() {
      this.loading = true;
      this.ruleForm = this.$route.params;
      let { aggrDsIds } = this.ruleForm;
      this.aggrDsIds = aggrDsIds ? aggrDsIds.split(",") : [];
      let ids = { id: "" };

      ids.id = this.$route.params.id;
      this.yulatableDatacloume = [];
      getdataBaseLinkDetailt(ids)
        .then((res) => {
          // 判定菜单类型是否可以修改
          // if(res &&!res.isChildren){
          //   this.prohibit = false
          // }
          if (res?.fieldPropConfigList) {
            let data = clone(res.fieldPropConfigList);
            data.map((it) => {
              it.showForRoles = it.showForRoles ? it.showForRoles.match(/(\d+)/g) : [];
            });
            this.shxiintableData = data;
            this.yulatableData = res.previewDataList;
            this.hasRemark = res.hasRemark;
            this.isDrill = res.isDrill;
          }
          if (res.isApp) this.ruleForm.isApp = Number(res.isApp);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },

    // 可以通过指定 Table 组件的 row-class-name 属性来为 Table 中的某一行添加 class，表明该行处于某种状态。
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    //当某个单元格被点击时会触发该事件
    cellClick(row, column) {
      if (column.property) {
        this.rowIndex = row.index;
        this.cloumIndex = column.property;
        this.rowValue = row[column.property];
        if (this.rowValue?.moduleType == 3) {
          this.rowValue.filterValue = this.rowValue.filterValue?.split("至");
        }
        this.$nextTick(() => {
          let idproperty = row.id + column.property;
          this.$refs[idproperty]?.focus();
        });
      }
    },
    // 字段属性中：显示顺序、字段名称、显示名称、字段类型、查询符、列宽输入的值
    inputblur(row) {
      console.log(row.showForRoles, 'showForRoles')
      row.showForRolesStr = ''
      if (row.showForRoles && row.showForRoles.length !== 0) {
        row.showForRoles.forEach((i) => {
          this.rolesOption.forEach((item) => {
            if (i === item.id) {
              row.showForRolesStr += item.roleName;
            }
          });
        });
      }
      console.log(row.showForRolesStr, "showForRolesStr");
      // if (this.rowValue !== row[this.cloumIndex]) {
      //   // 是否查询
      //   //  this.changelistrow(row);
      // }
    },
    // 字段属性操作（编辑按钮）弹窗确定按钮触发的事件
    dialogFormVisibleok() {
      // this.changelistrow(this.form);
      if (this.form.id) {
        this.shxiintableData = this.shxiintableData.map((item) => {
          if (item.id === this.form.id) {
            item = this.form;
          }
          return item;
        });
      } else {
        if (this.editRowIndex) {
          this.shxiintableData = this.shxiintableData.map((item, index) => {
            if (index + 1 === this.editRowIndex) {
              item = this.form;
            }
            return item;
          });
        } else {
          this.shxiintableData.unshift(this.form);
        }
      }
      this.dialogFormVisible = false;
      this.editRowIndex = null;
    },
    // 是否显示
    whetherToDisplay(row) {
      this.changelistrow(row);
    },

    // 查询支持多选
    queryMultipleSelections(row) {
      // this.changelistrow(row);
    },
    // 是否行转列
    // rowTransfer(row) {
    //   this.changelistrow(row);
    // },
    // // 是否备注主键
    // commentPK(row) {
    //   this.changelistrow(row);
    // },
    // 是否筛选
    changelistsharow(row) {
      // this.changelistrow(row);
    },
    // 是否合计、是否小计分支、是否小计
    changelistisSum(row) {
      // this.changelistrow(row);
    },
    // 操作
    setClick(row, index) {
      this.dialogFormVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
      this.editRowIndex = index + 1;
    },
    delClick(row, i) {
      if (row.id) {
        deleteDataSetList([row.id], this.$route.params.id).then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.dateillist();
        });
      } else {
        this.shxiintableData = this.shxiintableData.filter((item, index) => index !== i);
        this.$message.success("删除成功");
      }
    },
    addClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let data = this.ruleForm;
          if (!data.menuType) {
            this.$message.error("菜单类型不能为空！");
            return;
          }
          if (!data.sqlContent && data.menuType !== '0') {
            this.$message.error("SQL不能为空！");
            return;
          }
          if (data.menuType == 0) data.pid = "0";
          data["hasRemark"] = this.hasRemark;
          data["isDrill"] = this.isDrill;
          if (data.aggrDsNames instanceof Array) {
            if (data.aggrDsNames.length == 0) data.aggrDsNames = null;
          }
          // 当上级模块的值等于0时，则为顶级，否则要查看外键是否为空
          if (this.ruleForm.pid == "0") {
            adddataSetlist(data).then((res) => {
              if (res) {
                this.$message({ message: "保存成功", type: "success" });
                // this.$route.params = res? res : this.$route.params
                this.$route.params.id
                  ? this.dateillist()
                  : this.$router.replace(
                      {
                        name: "addDataSet",
                        params: res,
                      },
                      () => {
                        this.dateillist();
                      }
                    );
                // this.dateillist()
              }
              // this.$router.push({ name: "dataSet" });
            });
          } else {
            // 判断外键是否有填写
            let referCondition = this.ruleForm.referCondition;
            if (
              this.ruleForm.menuType != 0 && this.ruleForm.menuType !== '1' && 
              (referCondition == undefined ||
                referCondition == "" ||
                referCondition == null)
            ) {
              this.$message.error("外键不能为空");
            } else {
              adddataSetlist(data).then((res) => {
                this.$message({ message: "保存成功", type: "success" });
                // this.$route.params = res? res : this.$route.params
                this.$route.params.id
                  ? this.dateillist()
                  : this.$router.replace(
                      {
                        name: "addDataSet",
                        params: res,
                      },
                      () => {
                        this.dateillist();
                      }
                    );
                // this.$router.push({ name: "dataSet" });
              });
            }
          }
          this.changelistrow();
        }
      });
    },
    // 后退
    cancel() {
      this.$router.push({ name: "dataSet" });
    },
    // 加载Sql
    loadSql() {
      getloadSql(this.ruleForm).then((res) => {
        if (!(res.status == false)) {
          this.yulatableData = res?.previewDataList || [];
          this.yulatableDatacloume = res?.fieldPropConfigList;
          this.$message({ message: "加载成功", type: "success" });
        }
      });
    },
    // 选择数据集
    handleChange(item) {
      this.ruleForm.aggrDsNames = [];
      this.ruleForm.aggrDsIds = [];
      for (let i = 0; i < item.length; i++) {
        for (let j = 0; j < this.databaseMap.length; j++) {
          if (item[i] == this.databaseMap[j].id) {
            this.ruleForm.aggrDsIds.push(item[i]);
            this.ruleForm.aggrDsNames.push(this.databaseMap[j].dsName);
          }
        }
      }
      this.ruleForm.aggrDsIds = this.ruleForm.aggrDsIds.join(",");
      this.ruleForm.aggrDsNames = this.ruleForm.aggrDsNames.join(",");
    },
    // 数据库链接下拉框选项渲染
    getdataBase() {
      getdataBaseLinklist().then((res) => {
        this.getdataBasedata = res;
      });
      let obj = { pageNum: 1, pageSize: 500, queryKey: "" };
      // 上级模块、选择数据集下拉框的数据
      findByMenuType(obj).then((res) => {
        this.databaseMap = res.result;
      });
      findByMenuType().then((res) => {
        this.superiorModule = res;
      });
      findByMenuList().then((res) => {
        this.menuOptions = res;
      });
      // 获取所有角色
      getRoleListByUserName().then((res) => {
        this.rolesOption = res;
        this.$map['roleMap'] = res.reduce((map, it) => {
          map[it.id] = it.roleName;
          return map;
        }, {});
      });
    },
    // 数据库链接下拉框
    connNameChange(selectParams) {
      for (let i = 0; i < this.getdataBasedata.length; i++) {
        if (selectParams != "" && selectParams != null && selectParams != undefined) {
          if (selectParams == this.getdataBasedata[i].id) {
            this.ruleForm.connName = this.getdataBasedata[i].connName;
          }
        } else this.ruleForm.connName = "";
      }
    },
  },
};
</script>
<style scoped lang="less">
.setindex {
  // width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.buttonBox {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid black;
}

.formBox {
  padding: 10px;
  box-sizing: border-box;
}

.el-form-item {
  width: 340px;
}

.textareainput {
  width: 90%;

  /deep/ .el-form-item__content {
    width: 80%;
  }
}

.textareainputs {
  width: 90%;
  display: flex;

  /deep/ .el-form-item__content {
    width: 80%;
  }

  /deep/ .el-form-item__label {
    width: 110px;
  }
}

/deep/ .el-textarea__inner {
  height: 140px;
}

.el-tabs--border-card {
  flex: 1;
}

/deep/ .el-tabs--border-card > .el-tabs__content {
  height: calc(85% - 20px);
  padding: 10px 10px 5px 10px;
}
.allButton {
  width: 80%;
  margin: 5px 0 5px 20px;
  text-align: start;
  background: white;
  border: none;
  font-size: 14px;
  padding: 0;
}
.paixu {
  width: 300px;
}
</style>
